import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import Profiles from "./Profiles"
import { Train, Linkedin, Instagram, Download } from "lucide-react";
import teamIcon from "../Assets/Images/teamIcon.gif";
import Hero from "../Assets/Images/heroImg.jpg";
import Chirag from "../Assets/Images/chirag3.jpg";
import Vishal from "../Assets/Images/vishal3.jpg";

interface FeatureCardProps {
  title: string;
  description: string;
  align: "left" | "right";
}

// CONTENT  YOUR IMAGES HERE
const placeholderImage = Hero;
const profileImg1 = Chirag;
const profileImg2 = Vishal;

//  OUR TEAM SECTION INFO HERE
const teamMembers = [
  {
    name: "Chirag Sharma",
    role: "Startup Lead Instructor",
    image: profileImg1,
    desc: "3x Founding Engineer, Worked in Ed-tech, Ad-tech and Fin-tech. Build tech for startups that raised $9.5M in funding. ",
    linkedin: "https://www.linkedin.com/in/plusminuschirag/",
    instagram: "https://www.instagram.com/plusminuschirag/",
  },
  {
    name: "Vishal Bachchas",
    role: "FAANG Lead Instructor |Tech-Influencer @techpaywithvishal",
    image: profileImg2,
    desc: "Senior Engineer @ Microsoft, Ex Amazon, Ex UHG. DTU Alumni. Tech Influencer, helping people find their dream jobs and compensation parity.",
    linkedin: "https://www.linkedin.com/in/vishalbachchas/",
    instagram: "https://www.instagram.com/techpaywithvishal/",
  },
];

// WHY US CARDS
const FeatureCard: React.FC<FeatureCardProps> = ({
  title,
  description,
  align,
}) => {
  return (
    <motion.div
      className={`bg-white p-6 rounded-lg w-full shadow-lg mb-6 md:mb-0 md:max-w-[30vw] 
      ${align === "left" ? "md:mr-auto" : "md:ml-auto"}`}
      initial={{ opacity: 0, x: align === "left" ? 100 : -100 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true, amount: 0.5 }}
      transition={{ duration: 0.5 }}
    >
      <h3 className="text-xl md:text-2xl font-semibold mb-4 border-b-4 border-[#fd7e40] pb-2">
        {title}
      </h3>
      <p className="text-base md:text-lg">{description}</p>
    </motion.div>
  );
};

// downloading brochure handle click
// const handleInfoClick = () => {
//   const fileUrl = '/Brochure.pdf';
//   window.open(fileUrl, '_blank');
//   const link = document.createElement("a");
//   link.href = fileUrl;
//   link.setAttribute("download", "Brochure.pdf");
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

//  -------------------------------------------------MAIN FUNCTION --------------------------------------------------------------
const LandingPage: React.FC = () => {
  const whyUsRef = useRef<HTMLElement>(null);
  const teamRef = useRef<HTMLElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const scrollToSection = (ref: React.RefObject<HTMLElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="bg-[#F8F4F2] min-h-screen ">
      {/* -------------------------------------------Navbar---------------------------------------------------- */}
      <nav className="bg-[#F8F4F2]">
        <div className="container mx-auto px-6 md:px-20 py-4 flex justify-between items-center">
          <motion.div
            className="text-3xl font-bold flex items-center justify-center text-[#201E1C]"
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ type: "spring", stiffness: 100, damping: 20 }}
          >
            <Train className="h-10 w-10 text-[#201E1C]" />
            <span className="ml-3 text-3xl md:text-3xl font-bold">
              TheDeveloper<span className="text-[#fd7e40]">Train</span>
            </span>
          </motion.div>

          {/* Hamburger Menu for mobile */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-900 focus:outline-none"
            >
              {/* Hamburger icon */}
              <svg
                className="h-8 w-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>

          {/* Menu for larger screens */}
          <motion.div
            className="hidden md:flex space-x-8"
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ type: "spring", stiffness: 100, damping: 20 }}
          >
            <button
              onClick={() => scrollToSection(whyUsRef)}
              className="text-gray-600 text-lg font-semibold hover:text-gray-900 hover:underline"
            >
              Why Us
            </button>
            <button
              onClick={() => scrollToSection(teamRef)}
              className="text-gray-600 text-lg font-semibold hover:text-gray-900 hover:underline"
            >
              Our Team
            </button>
          </motion.div>
        </div>

        {/* Dropdown menu for mobile */}
        {isOpen && (
          <div className="md:hidden px-6 pb-4 bg-[#fd7e40]">
            <motion.div
              className="space-y-4"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", stiffness: 100, damping: 20 }}
            >
              <button
                onClick={() => {
                  scrollToSection(whyUsRef);
                  setIsOpen(false);
                }}
                className="block w-full text-right text-neutral-100 text-lg font-semibold hover:text-gray-900 hover:underline"
              >
                Why Us
              </button>
              <button
                onClick={() => {
                  scrollToSection(teamRef);
                  setIsOpen(false);
                }}
                className="block w-full text-right text-neutral-100 text-lg font-semibold hover:text-gray-900 hover:underline"
              >
                Our Team
              </button>
            </motion.div>
          </div>
        )}
      </nav>

      {/* ------------------------------------------------------Hero Section-------------------------------------------------- */}
      <section className="container h-auto md:h-[85vh] mx-auto px-6 md:px-32 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 items-center">
          <div>
            <motion.h1
              className="text-3xl md:text-6xl font-bold mb-6 md:mb-10"
              initial={{ y: -100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 70,
                damping: 15,
                delay: 0.2,
              }}
            >
              FAANG? Startups? 10x Pay? Remote? A
              <span className="font-serif">I</span>x Engineer? Join{" "}
              <span className="text-[#fd7e40]">The DeveloperTrain</span>
            </motion.h1>
            <motion.p
              className="text-base font-semibold md:text-2xl mb-6 md:mb-8 max-w-full md:max-w-[520px]"
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 70,
                damping: 15,
                delay: 0.4,
              }}
            >
              India's first platform that offers adventure and career at one
              place. Adventure makes you a different person, and impact learning
              makes you a better person.
            </motion.p>
            <div className="flex flex-col md:flex-row items-start md:items-baseline flex-wrap space-y-4 md:space-y-2 md:space-x-2">
              {/* Join Us Now Button */}
              <motion.button
                className="bg-[#fd7e40] text-white px-6 py-3 md:px-8 md:py-3 rounded-sm text-lg md:text-lg w-[100%] md:w-auto"
                initial={{ scale: 0, opacity: 0, x: -50, y: 50 }}
                animate={{ scale: 1, opacity: 1, x: 0, y: 0 }}
                transition={{
                  type: "spring",
                  stiffness: 70,
                  damping: 15,
                  delay: 0.6,
                }}
                onClick={() => {
                  // const fileUrl = "/Brochure.pdf";
                  // const link = document.createElement("a");
                  // link.href = fileUrl;
                  // link.setAttribute("download", "Brochure.pdf");
                  // document.body.appendChild(link);
                  // link.click();
                  // document.body.removeChild(link);
                  window.open("https://forms.gle/AThpadGuNeBkPBnW6", "_blank");
                }}
              >
                Join Us Now
              </motion.button>

              {/* New Download Button */}
              <motion.button
                className="border-[2.5px] border-[#fd7e40] text-[#fd7e40] text-lg p-[0.56rem] rounded-sm w-[100%] md:w-auto  flex justify-center items-center"
                initial={{ scale: 0, opacity: 0, x: -50, y: 50 }}
                animate={{ scale: 1, opacity: 1, x: 0, y: 0 }}
                transition={{
                  type: "spring",
                  stiffness: 70,
                  damping: 15,
                  delay: 0.6,
                }}
                onClick={() => {
                  const fileUrl = "/Brochure.pdf";
                  const link = document.createElement("a");
                  link.href = fileUrl;
                  link.setAttribute("download", "Brochure.pdf");
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              >
                Brochure
                <Download className="w-6 h-6 ml-2" />
              </motion.button>

              {/* exciting text */}
              <motion.p
                className="text-[#fd7e40] text-base md:text-xl font-semibold border-[2.5px] border-[#fd7e40] p-2 rounded-sm"
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0, scale: [1, 1.04, 1] }}
                transition={{
                  type: "spring",
                  stiffness: 70,
                  damping: 15,
                  delay: 0.6,
                  scale: {
                    repeat: Infinity,
                    duration: 1.2,
                    ease: "easeInOut",
                  },
                }}
              >
                Exciting Offers Till 30th Sept 2024
              </motion.p>
            </div>
          </div>

          <div className="flex justify-center w-full h-auto md:h-full">
            <motion.img
              src={placeholderImage}
              alt="Hero"
              className="rounded-lg object-cover w-full h-full"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 70,
                damping: 15,
                delay: 0.8,
              }}
            />
          </div>
        </div>
      </section>

      {/* ---------------------------------------------Why Choose Developer Train -------------------------------------- */}
      <section
        ref={whyUsRef}
        className="py-8 md:py-12 mt-16 bg-[#F8F4F2] overflow-x-hidden"
      >
        <div className="container mx-auto px-4 md:px-20">
          <motion.h2
            className="relative text-3xl md:text-5xl font-bold text-left mb-8 md:mb-16 md:ml-[4.5rem] pl-4 border-l-4 md:border-l-8 border-l-[#fd7e40]"
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, ease: "easeOut" }}
          >
            Why Ride{" "}
            <span className="text-[#fd7e40] relative group">
              The DeveloperTrain
              <span className="absolute bottom-0 left-0 w-full h-1 bg-neutral-900 transform scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></span>
            </span>{" "}
            ?
          </motion.h2>

          <div className="md:grid md:grid-cols-2 md:gap-4 md:border-l-[1px] md:border-l-[#fd7e40]">
            <FeatureCard
              title="Smart Preparation for Top Companies"
              description="Learn how to prepare effectively for internships and jobs at Amazon, Microsoft, and other leading companies. We stop all the confusions and put you on track for your dream companies from people who have done it. 🚀"
              align="right"
            />
            <div className="hidden md:block"></div>
            <div className="hidden md:block"></div>
            <FeatureCard
              title="Breaking Into High Paying Startups & Remote Jobs"
              description="Understand what it actually takes to get into high paying startups and remote jobs. Learn from the ones who have done it. What it means to be a Founding Engineer. Does working in startups really pay off? What is this ESOP lottery that's making people millionaire?"
              align="left"
            />
            <FeatureCard
              title="Become a AIx Engineer"
              description="You might have heard of 10x engineers or 100x ones, but it's time to embrace the reality and understand how LLMs and AI are changing the world and how you can become a AIx Engineer. Don't worry we got you covered, we will make you a AIx Engineer by the end of it"
              align="right"
            />
            <div className="hidden md:block"></div>
            <div className="hidden md:block"></div>
            <FeatureCard
              title="Unlimited 1:1 Sessions, Learn, StandOut, Win Big!"
              description="We will provide you with unlimited 1:1 sessions with our experts to help you with your resume, projects and what you can do more to stand out in the crowd."
              align="left"
            />
            <FeatureCard
              title="Fun Acitivites, Adventure, Impact Learning"
              description="Join us for a trek on sceneic views, adventure, learn about yourself, make new friends and have a life changing experience. Making learning super fun and interactive."
              align="right"
            />
          </div>
        </div>
      </section>

      {/* ------------------------------------------------------Meet the Team Section------------------------------------------- */}
      <section
  ref={teamRef}
  className="py-12 sm:py-16 md:py-24 px-4 sm:px-8 md:px-24 bg-[#F8F4F2]"
>
  <div className="container mx-auto px-4 sm:px-6 md:px-12">
    <motion.h2
      className="text-3xl sm:text-4xl md:text-5xl font-bold text-left mb-8 md:mb-16 border-l-8 pl-4 border-l-[#fd7e40] flex items-center flex-wrap"
      initial={{ x: -100, opacity: 0 }}
      whileInView={{ x: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      <span className="relative inline-block group mr-2">
        Meet Our <span className="text-[#fd7e40]">Team</span>
        <span className="absolute left-0 bottom-[-4px] w-0 h-1 bg-neutral-900 transition-all duration-300 group-hover:w-full"></span>
      </span>
      <img
        src={teamIcon}
        alt="Team Icon"
        className="w-12 h-12 sm:w-16 sm:h-16 md:w-20 md:h-20 mt-2 md:mt-0"
      />
    </motion.h2>

    <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 md:gap-12">
      {teamMembers.map((member, index) => (
        <motion.div
          key={index}
          className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col max-w-sm mx-auto h-[450px] sm:h-[500px]"
          initial={{
            x: index % 2 === 0 ? -100 : 100,
            opacity: 0,
          }}
          whileInView={{
            x: 0,
            opacity: 1,
          }}
          viewport={{ once: true }}
          transition={{
            type: "spring",
            stiffness: 150,
            damping: 15,
            delay: 0.2,
          }}
        >
          <div className="h-[63%] overflow-hidden">
            <img
              src={member.image}
              alt={member.name}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="h-[37%] p-4 flex flex-col justify-between">
            <div className="flex justify-between items-center">
              <h3 className="text-xl font-semibold mb-1">
                {member.name}
              </h3>
              <div className="flex items-center space-x-2">
                <a
                  href={member.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#fd7e40]"
                >
                  <Linkedin size={24} />
                </a>
                <a
                  href={member.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#fd7e40]"
                >
                  <Instagram size={24} />
                </a>
              </div>
            </div>
            <div className="text-lg mb-2 text-[#fd7e40]">
              {member.role.split('|').map((line, i) => (
                <p key={i}>{line}</p>
              ))}
            </div>
            <p className="text-gray-600 text-sm line-clamp-3 sm:line-clamp-none">
              {member.desc}
            </p>
          </div>
        </motion.div>
      ))}
    </div>
  </div>
</section>

      {/* -----------------------------------------moving profile section----------------------------------------------------- */}

            <Profiles />
            

      {/* --------------------------------------------------Call to Action-------------------------------------------------------- */}
      <section className="py-10 bg-[#fd7e40] overflow-hidden">
        <div className="container mx-auto px-6 text-center">
          <motion.h2
            className="text-3xl font-bold text-white mb-8"
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            Ready to Start Your Journey?
          </motion.h2>
          <motion.button
            className="bg-white text-[#fd7e40] px-8 py-3 rounded-lg text-lg font-semibold "
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ amount: 0.001 }}
            transition={{
              delay: 0.2,
              duration: 0.6,
            }}
            onClick={() => {
              const fileUrl = "/Brochure.pdf";

              const link = document.createElement("a");
              link.href = fileUrl;
              link.setAttribute("download", "Brochure.pdf");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              window.open("https://forms.gle/AThpadGuNeBkPBnW6", "_blank");
            }}
          >
            Be Part of This Journey
          </motion.button>
        </div>
      </section>
      {/* --------------------------------------------------------Footer------------------------------------------------------------ */}

      <footer className="bg-[#F8F4F2] py-6">
        <div className="container mx-auto px-6 md:px-20">
          <div className="flex flex-col justify-center items-center space-y-4 md:space-y-2">
            <motion.div
              className="flex items-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Train className="h-8 w-8 text-[#201E1C] mr-2" />
              <span className="text-2xl font-bold text-[#201E1C]">
                TheDeveloper<span className="text-[#fd7e40]">Train</span>
              </span>
            </motion.div>

            <motion.div
              className="text-center text-gray-600 text-xs md:text-sm mt-2 px-2"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              © {new Date().getFullYear()} TheDeveloperTrain. All rights reserved.
            </motion.div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
