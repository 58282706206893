import React from 'react';
import { motion } from 'framer-motion';
import { Linkedin } from 'lucide-react';
import './Profiles.css';

import Kush from "../Assets/Images/ProfileImages/kush sharma1.jpg";
import Astha from "../Assets/Images/ProfileImages/Astha2.jpeg";
import Anurag from "../Assets/Images/ProfileImages/anurag1.jpeg";
import Mohit from "../Assets/Images/ProfileImages/mohit1.jpeg";
import Abhishek from "../Assets/Images/ProfileImages/abhi1.jpeg";
import Piyush from "../Assets/Images/ProfileImages/Piyush1.jpeg";
import Sagar from "../Assets/Images/ProfileImages/sagar1.jpeg";

// Define the Profile interface
interface Profile {
  name: string;
  role: string;
  description: string;
  image: string;
  linkedin: string;
}

// Define props for ProfileCard component
interface ProfileCardProps {
  name: string;
  role: string;
  description: string;
  image: string;
  linkedin: string;
}

const profiles: Profile[] = [
  {
    name: "Anurag Joshi",
    role: "Software Engineer @Google",
    description: "Software Engineer with experience at Google and Amazon, specializing in building impactful, large-scale products.",
    image: Anurag,
    linkedin: "https://www.linkedin.com/in/anuragjo/",
  },
  {
    name: "Kush Sharma",
    role: "Execution Systems Developer",
    description: "Ex Optum, Execution Systems Developer @Auros with experience in high-frequency trading and building low-latency systems for the financial industry.",
    image: Kush,
    linkedin: "https://www.linkedin.com/in/kush-s-345b09144/",
  },
  {
    name: "Mohit Asswani",
    role: "Founding Engineer @Pintel",
    description: "Founding engineer @Pintel, acknowledged for his leadership in the development of scalable software systems and innovative AI solutions.",
    image: Mohit,
    linkedin: "https://www.linkedin.com/in/mohitasswani/",
  },
  {
    name: "Abhishek Sharma",
    role: "SDE-II @Amazon",
    description: "Software Engineer II @Amazon, renowned for his exceptional software development contributions and commitment to improving system performance and efficiency.",
    image: Abhishek,
    linkedin: "https://www.linkedin.com/in/abhishekk781/",
  },
  {
    name: "Astha Mohta",
    role: "SWE-III @Google",
    description: "Software Engineer III @Google, Ex Ansys, pioneers the development of the Cloud Spanner Migration Tool streamlining zero-downtime transitions.",
    image: Astha,
    linkedin: "https://www.linkedin.com/in/astha-mohta/",
  },
  {
    name: "Piyush Makhija",
    role: "CTO & Co-founder @Moneyflo",
    description: "CTO and Co-Founder @Moneyflo.ai, Ex Vahan Inc., applies expertise in AI and data analytics to revolutionize business operations.",
    image: Piyush,
    linkedin: "https://www.linkedin.com/in/piyushmakhija/",
  },
  {
    name: "Sagar Sehgal",
    role: "Software Engineer 2 @Opendoor",
    description: "Software Engineer @Opendoor, Ex Amazon, Ex Ajio, Founding team member @ByteLearn, expertise in high-impact microservices and distributed systems.",
    image: Sagar,
    linkedin: "https://www.linkedin.com/in/thesagarsehgal/",
  },
];

// Card component for individual profile
const ProfileCard: React.FC<ProfileCardProps> = ({ name, role, description, image, linkedin }) => (
  <div className="rounded-lg shadow-lg overflow-hidden w-[18rem] flex-shrink-0 flex flex-col">
    {/* Set a fixed height for the image to make it smaller */}
    <div className="h-[40vh] overflow-hidden">
      <img src={image} alt={name} className="w-full h-full object-cover" />
    </div>
    <div className="p-4 flex flex-col items-left">
      <div className="flex items-center justify-between w-full mb-2">
        <h3 className="text-xl font-semibold">{name}</h3>
        <a 
          href={linkedin} 
          target="_blank" 
          rel="noopener noreferrer"
          className="text-[#fd7e40] hover:text-[#e56a2c]"
        >
          <Linkedin size={20} />
        </a>
      </div>
      <p className="text-[#fd7e40] mb-2">{role}</p>
      {/* Ensure text wrapping and prevent overflow */}
      <p className="text-gray-600 text-sm text-left card-text">
        {description}
      </p>
    </div>
  </div>
);


// Main Profiles component
const Profiles: React.FC = () => {
  return (
    <section className="py-12 bg-[#F8F4F2] overflow-hidden">
      <div className="container mx-auto px-4">
        {/* Section title with animation */}
        <motion.h2
          className="text-3xl sm:text-4xl md:text-5xl md:ml-32 font-bold text-left mb-8 md:mb-16 border-l-8 pl-4 border-l-[#fd7e40] flex items-center"
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, ease: "easeOut" }}
        >
          <span className="relative inline-block group mr-2">
            Meet Your <span className="text-[#fd7e40]">Mentors</span>
            <span className="absolute left-0 bottom-[-4px] w-0 h-1 bg-neutral-900 transition-all duration-300 group-hover:w-full"></span>
          </span>
        </motion.h2>
        <div className="w-full overflow-hidden">
          <div 
            className="flex py-12 w-fit gap-6 px-3 carouselInfiniteAnimation flex-shrink-0"
          >
            {[...profiles, ...profiles].map((profile, index) => (
              <ProfileCard key={profile.name + index} {...profile} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profiles;
