// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carouselInfiniteAnimation:hover {
    animation-play-state: paused;
}

.carouselInfiniteAnimation {
    white-space: nowrap;
    animation-iteration-count: infinite;
    animation-name: carouselInfiniteScroll;
    animation-timing-function: linear;
    animation-duration: 15s;
    animation-direction: normal;
}

.carouselInfiniteAnimation > * {
    flex-shrink: 0; /* Prevent children from shrinking */
}

@keyframes carouselInfiniteScroll {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-50%);
    }
}

/* NEW: Allow text wrapping inside cards */
.card-text {
    white-space: normal;
    overflow-wrap: break-word;
}
`, "",{"version":3,"sources":["webpack://./src/components/Profiles.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;AAEA;IACI,mBAAmB;IACnB,mCAAmC;IACnC,sCAAsC;IACtC,iCAAiC;IACjC,uBAAuB;IACvB,2BAA2B;AAC/B;;AAEA;IACI,cAAc,EAAE,oCAAoC;AACxD;;AAEA;IACI;QACI,yBAAyB;IAC7B;IACA;QACI,2BAA2B;IAC/B;AACJ;;AAEA,0CAA0C;AAC1C;IACI,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":[".carouselInfiniteAnimation:hover {\n    animation-play-state: paused;\n}\n\n.carouselInfiniteAnimation {\n    white-space: nowrap;\n    animation-iteration-count: infinite;\n    animation-name: carouselInfiniteScroll;\n    animation-timing-function: linear;\n    animation-duration: 15s;\n    animation-direction: normal;\n}\n\n.carouselInfiniteAnimation > * {\n    flex-shrink: 0; /* Prevent children from shrinking */\n}\n\n@keyframes carouselInfiniteScroll {\n    0% {\n        transform: translateX(0%);\n    }\n    100% {\n        transform: translateX(-50%);\n    }\n}\n\n/* NEW: Allow text wrapping inside cards */\n.card-text {\n    white-space: normal;\n    overflow-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
